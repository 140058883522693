<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <span class="brand-logo">
          <!-- <b-img :src="appLogoImage" width="180px" alt="logo" /> -->
          <b-img
            v-if="skin === 'light'"
            :src="appLogoImage"
            width="180px"
            alt="logo"
          />
          <b-img v-else :src="appDarkLogoImage" alt="logo" width="180px" />
        </span>
        <!-- <h2 class="brand-text text-primary ml-1">
          Secusy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome to Secusy!
          </b-card-title>
          <b-card-text class="mb-2 text-primary">
            Two Factor Authentication Enabled in Your Account Please Enter the
            OTP to Continue.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group
                label="Enter OTP from your authenticator App"
                label-for="login-otp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="OTP"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-otp"
                    v-model="userOtp"
                    :state="errors.length > 0 ? false : null"
                    name="login-otp"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                <span v-if="loading">Please wait</span>
                <span v-else>Continue</span>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 text-primary">
            <b-link @click="logout" style="color: #00cfe8 !important">
              <feather-icon icon="ArrowLeftIcon" class="mr-50" />Back to Login
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import useAppConfig from "@core/app-config/useAppConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      userOtp: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      loading: "",
      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },

  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          // const options = {
          //   method: 'POST',
          //   // headers: { 'content-type': 'application/json' },
          //   data: {
          //     username: this.userEmail,
          //     password: this.password,
          //   },
          //   url: 'http://localhost:8001/api-token-auth/',
          // }
          let data = {
            first_auth_token: this.$route.params.id,
            one_time_password: this.userOtp,
            is_2fa_enabled: 1,
          };
          // console.log(this.$store)
          this.$store
            .dispatch("app/login", data, { root: true })
            .then(
              () => {
                // let url = "/dashboard";
                // if (!this.$store.state.app.user.is_staff) url = "/dashboard";
                // this.$router.replace(localStorage.getItem("from_url") || url);
                this.$router.push({ name: "home" });
              }
              // this.$router.push({ name: 'home'})
            )
            .catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  // title:"Account locked: too many login attempts.Please try again after 60 minutes",
                  // title:"Your account is locked for 24 hours due suspicious activity. Please try after 17.5 hours.",
                  // title:"Your account is locked for 60 minutes due suspicious activity. Please try after 20 minutes.",
                  // title: "Unable to log in with provided credentialss.",
                  title: err.data.error,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              localStorage.removeItem("from_url");
              // console.log("finally");
              this.loading = false;
              // this.$router.go()
            });
        }
      });
    },
    logout: function () {
      this.$store.dispatch("app/logout");
      if (this.$route.name != "login") this.$router.push("/login");
    },
  },
  setup() {
    const { appName, appLogoImage, appDarkLogoImage } = $themeConfig.app;
    const { skin } = useAppConfig();
    return {
      // App Name
      appName,
      appLogoImage,
      appDarkLogoImage,
      skin,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
[dir] .dark-layout a {
  color: #fff !important;
}
</style>
